.skills{
    grid-column: 2 / 11;
    height: 700px;
    z-index: 9;
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 4em 0; 
    position: relative;
}
.skills .pro{
  position: absolute;
}
.skills .pro:nth-child(1){
  width: 100%;
  display: flex;
  gap: 50px;
}
.skills .pro:nth-child(2){
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.239);
  right: 50px;
  padding-top: 90px;
  
}
.skills .pro:nth-child(3){
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.239);
  top: 98px;
  left: 65px;
  padding: 33px;
}

/*media*********************************/
  @media (max-width:900px){
     .skills{
      flex-direction: column;
     }
     .skills .pro:nth-child(3) {
      top: -80px;
      left: inherit;
      width: 90%;
    }
    .skills .pro:nth-child(1) {
      padding: 90px; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .skills .pro:nth-child(2) {
      right: inherit;
      padding-top:40px;
      bottom: 50px;
      width: 90%;
    } 
   
  }