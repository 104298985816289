.pro-contianer{
    position: relative;
  }
  .pro{
    border-radius: 10px;
    padding: 60px;
    background: linear-gradient(to right,rgba(132, 132, 132, 0.337),rgba(92, 92, 92, 0.4));
    backdrop-filter: blur(7px);
    color: #fff;
    line-height: 28px;
    position: relative;
    overflow: hidden;
    z-index: 5;
  }
  .vid{
    position: absolute;
    border-radius: 10px;
    top: -100px;
    right: -40px;
    opacity: 1;
    height: 90%;
    width:70%;
    z-index: 4;
    cursor: pointer;
    overflow: hidden;
    transition: all 1s ease-in-out;
  }
  .vid video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .vid:hover{
    z-index: 7;
    transform: scale(1.2);
  }
  .case-st{
    display: block;
    position: absolute;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    top: -20px;
    left: -100px;
    height: 200px;
    width:250px ;
    z-index: 1;
    transition: all 1s ease-in-out;
  }
  .case-st:hover{
    z-index: 7;
    scale: 2.2;
  }
  .case-st img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .left-pro .vid{
    right: inherit;
    left: -40px;
  }
  .left-pro .case-st{
    left:inherit;
    right:-100px;
  }

  /*media*********************************/
  @media (max-width:900px){
     .vid{
      display: none;
     }
     .pro{
      padding-top: 300px;
     }
     .case-st,.left-pro .case-st{
      left: 50%;
      transform: translateX(-50%);
      width: 450px;
      height: 350px;
      z-index: 7;
      right: 0;
     }
     .case-st:hover{
      scale: 1;
    }
  }
  @media (max-width:622px){
    .pro-contianer{
       display: flex;
       justify-content: center;
    }
    .pro{
      padding-top: 250px;
     }
     .case-st,.left-pro .case-st{
      width: 90%;
      height: 40%;
     }
  }