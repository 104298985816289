.glassy{
    border-radius: 10px;
    padding:4em 3em;
    background: rgba(132, 132, 132, 0.337);
    backdrop-filter: blur(7px);
    color: #fff;
    line-height: 28px;
    position: relative;
    z-index: 30;
    overflow: hidden;
    width: 100%;
    height: 90vh;
  }
  span.style{
    display: inline-block;
    position: absolute;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background: rgba(46, 159, 251, 0.753);
    z-index: -1;
    filter: blur(60px);
  }
  span.style:nth-child(1){
    top: 16px;
    left: -10px;
    background: rgb(255, 0, 115);
  }
  span.style:nth-child(2){
    top:-15px;
    left: 30px;
    background: rgba(0, 51, 255, 0.655);
  }
  span.style:nth-child(3){
    background: rgba(106, 233, 253, 0.595);
  }
  .glassy p{
    font-size: 1.09em;
  }
  .coll-actian{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 40px 0;
  }
  .coll-actian a{
     width: 180px;
     padding: 10px;
     text-align: center;
     border-radius: 7px;
     color: #fff;
     overflow: hidden;
     position: relative;
     z-index: 2;
  }
  .coll-actian a:nth-child(2){
    border: 1px solid rgba(255, 255, 255, 0.539);
  }
  .coll-actian a:nth-child(2):hover{
   border: 1px solid transparent;
  background: #fff;
  color: black; 
  }
  .coll-actian a span{
    transition: all  .5s linear;
  }
  .coll-actian a:hover span.style:nth-child(1){
    top: -30px;
    left: -19px;
    filter: blur(30px);
  }
  .coll-actian a:hover span.style:nth-child(2){
    top: 10px;
    left: 10px;
  }
  .coll-actian a:hover span.style:nth-child(3){
    top: 0px;
    left: 40px;
  }
  .links{
    display: flex;
    gap: 20px;
  }
  .links a{
    color: rgba(255, 255, 255, 0.663);
    font-size: 1.4em;
  }
  .links a:hover{
    color: #fff;
  }
  .decor{
    position: absolute;
    right: 200px;
    bottom: 90px;
  }
  /*media*********************************/
@media (max-width:622px){
  .glassy{
    height: auto;}
}

