.navbar{
    position: fixed;
    z-index: 100;
    color: #fff;
    right:30px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    direction: rtl;
  }
  .logo{
    width: 75px;
    height: 75px;
    position: relative;
    right: -15px;
  }
  .logo img{
    width: 100%;
    height: 100%;
  }
  
  .navbar ul{
   display: flex;
   flex-direction: column;
  }
  .navbar ul li{
    margin: 50px 0;
    position: relative;
    cursor: pointer;
  }
  .navbar ul li::after{
    position: absolute;
    content: "";
    top: 29px;
    right:-11px;
     width: 2px;
     height:80px;
     background: #fff;
  }
  .navbar ul li:last-child::after{
     height: unset;
  }
  .navbar ul a{
    color: #fff;
  }
  .navbar ul li:hover, .navbar ul li:hover a,
  .navbar ul li.active, .navbar ul li.active a{
    color: #d000db;
  }
  /*media*********************************/
@media (max-width:622px){
  .navbar{
    direction: ltr;
    position: fixed;
    top: 0px;
    left: 0px;
    height:80px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 20px;
    padding: 30px;
    width: 100%;
    backdrop-filter: blur(5px);
    flex-direction: row;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.239);
  }
  .logo{
    width: 60px;
    height: 60px;
    left: 0;
    right: unset;
  }
  .navbar ul{
    flex: 1;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    list-style: none;
  }
  .navbar ul li{
    margin: 0;
    font-size: 17px;
  }
  .navbar ul li::after{
    height: unset;
  }

}