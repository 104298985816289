@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  canvas{
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  a{
    text-decoration: none;
    font-size: 1.1em;
  }
  html{
    scroll-behavior: smooth;
  }
  body{
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    color: #fff;
  }
  main{
    position: absolute;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12,1fr);
  }
  header{
    grid-column: 2 / 5;
    margin-bottom: 25px;
  }
  h1,h2,h4,h3,p{
    color: #fff;
    z-index: 10;
  }
  h1{
   font-size: 1.7em;
    padding: .4em 0;
  }
  h1 span{
    font-family: 'Dancing Script';
    font-size: 1.7em;
  }
  h2{
    font-size: 1.6em;
    margin-bottom: .9em;
  }
  h4{
    font-size: 1.2em;
   font-weight: normal;
  }
  h3{
    font-size: 1.4em;
    margin-bottom: 40px;
  }
  p{
    font-size: 1.3rem;
  }
  section{
  grid-column: 2/8;
  height: 100vh;
  width: 100%;
  z-index: 9;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 140px;
  }
  section.tow{
    grid-column:6 / 12;
    width: 100%;
  }

  /*media*********************************/
@media (max-width:900px){
 
  section,section.tow{
    display: block;
    grid-column: 2 / 11;
}
}
@media (max-width:622px){
  body{
    font-size: 14px;
    padding-top: 70px;
    overflow-x: hidden;
  }
  section ,section.tow{
    grid-column: 1/ 12;
    height: auto;
    margin: 60px 0px;
  }
}
  